import { BaggageCategoryEnum, BaggageCategoryEnumCode } from '@/enums/baggage-category.enum';
import { BaggageHandlingTypeEnum, BaggageHandlingTypeEnumCode } from '@/enums/baggage-handling-type.enum';
import { BaggageShapeEnum, BaggageShapeEnumCode } from '@/enums/baggage-shape.enum';
import { BaggageStatusEnum } from '@/enums/baggage-status.enum';
import { PrefectureEnum, PrefectureEnumCode } from '@/enums/prefecture.enum';
import { BaggageSearchResult } from '@/models/baggage-search-result';
import { DateTimeValue } from '@/models/vo/datetime';
import { Enum } from '@/types/enum';
import { ExcludeMethods } from '@/types/lang';
import { AgreementUtil, BaggageUtil, DateUtil, Util } from '@/util';
import { ValidateResult, Validator } from '@/validator';
import dayjs from 'dayjs';
import _ from 'lodash';
import { CompanyProfile } from '@/models/company';
import { NegotiationTypeEnum, NegotiationTypeEnumCode } from '@/enums/negotiation-type.enum';
import { FormValidatable, FormValidator } from '@/models/validate-helper';
import { JsonUtil } from '@/util/json';
import { DateValue } from '@/models/vo/date';
import { TruckWeightEnum, TruckWeightEnumCode } from '@/enums/truck-weight.enum';
import { TruckModelEnum, TruckModelEnumCode } from '@/enums/truck-model.enum';
import { SortOrder } from './vo/pagination';
import { BaggageSortKeyCode } from '@/const';
import { DateTimeRangePickerValue } from '@/_components/ui/types/date-time-range-picker-type';
import { BaggageFreightValue } from '@/models/vo/baggage-freight';
import { TruckHeightEnumCode } from '@/enums/truck-height.enum';
import { TruckWidthEnumCode } from '@/enums/truck-width.enum';
import { CompanyPlace, CompanyPlaceList } from './company-place';
import { ValueObject } from '@/models/vo/value-object';
import { BaggageTemperatureZoneEnum, BaggageTemperatureZoneEnumCode } from '@/enums/baggage-temperature-zone.enum';
import { DeliveryDateTimeRange, DeliveryDateTimeRangeType } from '@/models/vo/delivery-datetime-range';
import { Circle } from '@/models/circle';
import { Truck } from '@/models/truck';

export type LargeTruckAvailability = 'available' | 'unavailable';
export type BaggageHighwayFarePaymentOption = 'available' | 'unavailable';

/**
 * APIとの通信用
 */
export interface BaggageList {
    pageSize: number;
    totalPageCount: number;
    totalRecordCount: number;
    currentPageNumber: number;
    data: Baggage[];
}

export interface MaskedBaggageList {
    pageSize: number;
    totalPageCount: number;
    totalRecordCount: number;
    currentPageNumber: number;
    data: MaskedBaggage[];
}

export class BaggageListModel {
    pageSize: number;
    totalPageCount: number;
    totalRecordCount: number;
    currentPageNumber: number;
    data: (Baggage & { viewerCount: number })[];

    constructor(list: BaggageList, viewCounts: BaggageViewMemberCount[]) {
        const viewCountMap = _.keyBy(viewCounts, 'baggageId');

        this.pageSize = list.pageSize;
        this.totalPageCount = list.totalPageCount;
        this.totalRecordCount = list.totalRecordCount;
        this.currentPageNumber = list.currentPageNumber;
        this.data = list.data.map((item) => {
            return {
                ...item,
                viewerCount: viewCountMap[item.id]?.memberCount ?? 0
            };
        });
    }
}

/**
 * APIとの通信用
 */
export interface Baggage {
    id: number;
    circle?: Circle;
    companyId: number;
    status: BaggageStatusEnum;
    departureMin: string;
    departureMax: string;
    departurePref: Enum;
    departureCity: string;
    departureAddress?: string;
    loadingTimeNote?: string;
    arrivalMin: string;
    arrivalMax: string;
    arrivalPref: Enum;
    arrivalCity: string;
    arrivalAddress?: string;
    unloadingTimeNote?: string;
    category: Enum<BaggageCategoryEnumCode>;
    type: string;
    shape: Enum<BaggageShapeEnumCode>;
    temperatureZone: Enum<BaggageTemperatureZoneEnumCode>;
    paletteCount?: number;
    paletteHeight?: number;
    paletteWidth?: number;
    totalCount?: number;
    totalVolume?: number;
    totalWeight?: number;
    loading?: Enum<BaggageHandlingTypeEnumCode>;
    unloading?: Enum<BaggageHandlingTypeEnumCode>;
    truckWeight: Enum<TruckWeightEnumCode>;
    truckModel: Enum<TruckModelEnumCode>;
    truckHeight?: Enum;
    truckWidth?: Enum;
    largeTruckFlg?: boolean;
    truckEquipment?: string;
    share: boolean;
    express: boolean;
    multipleBaggage: boolean;
    freight?: number;
    highwayFareFlg: boolean;
    staffName: string;
    description?: string;
    underNegotiation: boolean;
    paymentDate?: string;
    negotiationType: Enum<NegotiationTypeEnumCode>;
    entryTm: string;
    traboxBaggageId?: number;
    shipperName?: string;
    labelText?: string;
    labelColor?: string;
}

export interface MaskedBaggage {
    id: number;
    circle?: Circle;
    companyId: number;
    status?: BaggageStatusEnum;
    departureMin?: string;
    departureMax?: string;
    departurePref?: Enum;
    departureCity?: string;
    departureAddress?: string;
    loadingTimeNote?: string;
    arrivalMin?: string;
    arrivalMax?: string;
    arrivalPref?: Enum;
    arrivalCity?: string;
    arrivalAddress?: string;
    unloadingTimeNote?: string;
    category?: Enum<BaggageCategoryEnumCode>;
    type?: string;
    shape?: Enum<BaggageShapeEnumCode>;
    temperatureZone?: Enum<BaggageTemperatureZoneEnumCode>;
    paletteCount?: number;
    paletteHeight?: number;
    paletteWidth?: number;
    totalCount?: number;
    totalVolume?: number;
    totalWeight?: number;
    loading?: Enum<BaggageHandlingTypeEnumCode>;
    unloading?: Enum<BaggageHandlingTypeEnumCode>;
    truckWeight?: Enum<TruckWeightEnumCode>;
    truckModel?: Enum<TruckModelEnumCode>;
    truckHeight?: Enum;
    truckWidth?: Enum;
    largeTruckFlg?: boolean;
    truckEquipment?: string;
    share?: boolean;
    express?: boolean;
    multipleBaggage?: boolean;
    freight?: number;
    highwayFareFlg?: boolean;
    staffName?: string;
    description?: string;
    underNegotiation?: boolean;
    paymentDate?: string;
    negotiationType?: Enum<NegotiationTypeEnumCode>;
    entryTm: string;
    traboxBaggageId?: number;
    shipperName?: string;
    labelText?: string;
    labelColor?: string;
}

export class BaggageRegisterForm {
    circleId: number | undefined;
    // 1.発情報
    departureMin: string;
    departureMax: string;
    departureType?: DeliveryDateTimeRangeType;
    departurePref: { code?: string };
    departureCity?: string;
    departureAddress?: string;
    departureCompanyPlaceId?: number;
    departureIsCompanyPlace: boolean;
    loadingTimeNote?: string;
    // 2.着情報
    arrivalMin: string;
    arrivalMax: string;
    arrivalType?: DeliveryDateTimeRangeType;
    arrivalPref: { code?: string };
    arrivalCity?: string;
    arrivalAddress?: string;
    arrivalCompanyPlaceId?: number;
    arrivalIsCompanyPlace: boolean;
    unloadingTimeNote?: string;
    // 3.引っ越し
    category: { code?: BaggageCategoryEnumCode };
    // 4.荷種
    type: string;
    // 5.荷姿
    shape?: Enum<BaggageShapeEnumCode>;
    // 6.パレット情報
    paletteCount?: string;
    paletteHeight?: string;
    paletteWidth?: string;
    // 7.その他情報
    totalCount?: string;
    totalVolume?: string;
    // 8.総重量
    totalWeight?: string;
    // 9.ドライバー作業
    loading?: Enum<BaggageHandlingTypeEnumCode>;
    unloading?: Enum<BaggageHandlingTypeEnumCode>;
    // 10.トラック情報
    truckWeight: { code?: string };
    truckModel: { code?: string };
    truckHeight?: { code: string };
    truckWidth?: { code: string };
    largeTruckFlg?: boolean;
    truckEquipment?: string;
    // 11.台数
    truckCount: string;
    // 12.フラグ情報
    share: boolean;
    express: boolean;
    // 13.運賃
    freight?: string;
    highwayFareFlg?: boolean;
    // 14.担当者
    staffName: string;
    // 15.備考
    description?: string;
    // 16.商談中
    underNegotiation: boolean;
    // 17.入金予定日
    paymentDate?: string;
    // 18.連絡方法
    negotiationType?: Enum<NegotiationTypeEnumCode>;
    // 19.温度帯
    temperatureZone: { code?: string };
    // 20.荷主名
    shipperName: string | undefined;
    // 21.ラベルのテキスト
    labelText: string | undefined;
    // 22.ラベルの色
    labelColor: string | undefined;

    constructor(param: Partial<BaggageRegisterForm> | null = null) {
        this.circleId = param?.circleId;
        // 1.発情報
        this.departureMin = param?.departureMin ?? '';
        this.departureMax = param?.departureMax ?? '';
        this.departureType = param?.departureType;
        this.departurePref = param?.departurePref ?? {};
        this.departureCity = param?.departureCity ?? '';
        this.departureCompanyPlaceId = param?.departureCompanyPlaceId;
        this.departureIsCompanyPlace = param?.departureIsCompanyPlace ?? false;
        this.departureAddress = param?.departureAddress ?? '';
        this.loadingTimeNote = param?.loadingTimeNote;
        // 2.着情報
        this.arrivalMin = param?.arrivalMin ?? '';
        this.arrivalMax = param?.arrivalMax ?? '';
        this.arrivalType = param?.arrivalType;
        this.arrivalPref = param?.arrivalPref ?? {};
        this.arrivalCity = param?.arrivalCity ?? '';
        this.arrivalAddress = param?.arrivalAddress ?? '';
        this.arrivalCompanyPlaceId = param?.arrivalCompanyPlaceId;
        this.arrivalIsCompanyPlace = param?.arrivalIsCompanyPlace ?? false;
        this.unloadingTimeNote = param?.unloadingTimeNote;
        // 3.引っ越し
        this.category = param?.category ?? { code: 'BC1' };
        // 4.荷種
        this.type = param?.type ?? '';
        // 5.荷姿
        this.shape = param?.shape;
        // 6.パレット情報
        this.paletteCount = param?.paletteCount ?? '';
        this.paletteHeight = param?.paletteHeight ?? '';
        this.paletteWidth = param?.paletteWidth ?? '';
        // 7.その他情報
        this.totalCount = param?.totalCount ?? '';
        this.totalVolume = param?.totalVolume ?? '';
        // 8.総重量
        this.totalWeight = param?.totalWeight ?? '';
        // 9.ドライバー作業
        this.loading = param?.loading;
        this.unloading = param?.unloading;
        // 10.トラック情報
        this.truckWeight = param?.truckWeight ?? { code: 'TW19' };
        this.truckModel = param?.truckModel ?? { code: 'TM17' };
        this.truckHeight = param?.truckHeight;
        this.truckWidth = param?.truckWidth;
        this.largeTruckFlg = param?.largeTruckFlg;
        this.truckEquipment = param?.truckEquipment;
        // 11.台数
        this.truckCount = param?.truckCount ?? '1';
        // 12.フラグ情報
        this.share = param?.share ?? false;
        this.express = param?.express ?? false;
        // 13.運賃
        this.freight = param?.freight ?? '0';
        this.highwayFareFlg = param?.highwayFareFlg ?? false;
        // 14.担当者
        this.staffName = param?.staffName ?? '';
        // 15.備考
        this.description = param?.description ?? '';
        // 16.商談中
        this.underNegotiation = param?.underNegotiation ?? false;
        // 17.入金予定日
        this.paymentDate = param?.paymentDate;
        // 18.連絡方法
        this.negotiationType = param?.negotiationType;
        // 19.温度帯
        this.temperatureZone = param?.temperatureZone ?? { code: BaggageTemperatureZoneEnum.Ambient.code };
        // 20.荷主名
        this.shipperName = param?.shipperName;
        // 21.ラベルのテキスト
        this.labelText = param?.labelText;
        // 22.ラベルの色
        this.labelColor = param?.labelColor;
    }
}

export type TmpBaggageLocation = {
    prefecture?: PrefectureEnum;
    city?: string;
    address?: string;
    companyPlace?: CompanyPlace;
    isCompanyPlace?: boolean;
};

export class TmpBaggageLocationValue extends ValueObject<TmpBaggageLocation> {
    constructor(value: TmpBaggageLocation) {
        super(value);
    }

    updatePrefecture(prefecture: PrefectureEnum | undefined): TmpBaggageLocationValue {
        return new TmpBaggageLocationValue({
            ...this.value,
            prefecture
        });
    }

    updateCity(city: string | undefined): TmpBaggageLocationValue {
        return new TmpBaggageLocationValue({
            ...this.value,
            city
        });
    }

    updateAddress(address: string | undefined): TmpBaggageLocationValue {
        return new TmpBaggageLocationValue({
            ...this.value,
            address
        });
    }

    updateCompanyPlace(companyPlace: CompanyPlace | undefined): TmpBaggageLocationValue {
        return new TmpBaggageLocationValue({
            ...this.value,
            companyPlace
        });
    }

    updateIsCompanyPlace(isCompanyPlace: boolean | undefined): TmpBaggageLocationValue {
        return new TmpBaggageLocationValue({
            isCompanyPlace
        });
    }

    get prefecture(): PrefectureEnum | undefined {
        return this.value.isCompanyPlace ? this.value.companyPlace?.pref as PrefectureEnum : this.value.prefecture;
    }

    get city(): string | undefined {
        return this.value.isCompanyPlace ? this.value.companyPlace?.city : this.value.city;
    }

    get address(): string | undefined {
        if (!this.value.isCompanyPlace) return this.value.address;
        const address = this.value.companyPlace?.address;
        const placeName = this.value.companyPlace?.placeName;
        if (!address) return placeName;
        return `${address}・${placeName}`;
    }
}

export class BaggageRegisterFormModel extends BaggageRegisterForm {
    // 17.要相談
    negotiate: boolean;
    // 18.変更前荷種
    previousType: string;
    // 19. 地点マスタ一覧
    placeList?: CompanyPlaceList;

    departureLocation: TmpBaggageLocationValue;
    arrivalLocation: TmpBaggageLocationValue;

    /**
     * コンストラクタ
     */
    constructor(param: Partial<BaggageRegisterFormModel> | null = null) {
        super(param);
        // 発日時は妥当性検査を経て再設定
        this.departure = _.clone(this.departure);
        // 着日時は妥当性検査を経て再設定
        this.arrival = _.clone(this.arrival);
        // 17.要相談
        this.negotiate = !this.freight;
        // 18.変更前荷種
        this.previousType = param?.previousType ?? '';
        // 連絡方法
        this.negotiationType = param?.negotiationType ?? NegotiationTypeEnum.Online;

        this.departureLocation = new TmpBaggageLocationValue(
            param?.departureLocation?.value ? param.departureLocation.value : {}
        );
        this.arrivalLocation = new TmpBaggageLocationValue(
            param?.arrivalLocation?.value ? param.arrivalLocation.value : {}
        );
    }

    /**
     * 発日時
     */
    get departure(): DeliveryDateTimeRange | null | undefined {
        const defaultValue = DeliveryDateTimeRange.typeOf('Day', DateUtil.now());
        if (!defaultValue) throw new Error('Invalid DeliveryDateTimeRange construction.');

        if (!this.departureMin || !this.departureMax || !this.departureType) return defaultValue;

        return DeliveryDateTimeRange.typeOf(this.departureType, this.departureMin, this.departureMax) ?? defaultValue;
    }

    set departure(param: DeliveryDateTimeRange | null | undefined) {
        const defaultValue = DeliveryDateTimeRange.typeOf('Day', DateUtil.now());
        if (!defaultValue) throw new Error('Invalid DeliveryDateTimeRange construction.');

        const criteria = DateUtil.now().endOf('hour');
        const departure = param && Validator.validateDateTimeRangeType(param, criteria).result ? param : defaultValue;

        const [min, max] = departure.rawValuesAsString();
        this.departureMin = min;
        this.departureMax = max;
        this.departureType = departure.type;
    }

    get departureDateTimeRange(): DateTimeRangePickerValue {
        return { min: this.departureMin, max: this.departureMax, type: this.departureType };
    }

    set departureDateTimeRange(value: DateTimeRangePickerValue) {
        const { min, max, type } = value;
        // 出発日時を書き換え
        this.departureMin = min ?? '';
        this.departureMax = max ?? '';
        this.departureType = type;
    }

    get departurePrefCode(): PrefectureEnumCode {
        return this.departurePref.code as PrefectureEnumCode;
    }

    set departurePrefCode(value: PrefectureEnumCode) {
        const changed = this.departurePref.code !== value;
        this.departurePref = { code: value };
        if (changed) {
            this.departureCity = '';
            this.departureAddress = '';
        }
    }

    /**
     * 着日時
     */
    get arrival(): DeliveryDateTimeRange | null | undefined {
        const defaultValue = DeliveryDateTimeRange.typeOf('Day', DateUtil.now().add(1, 'day'));
        if (!defaultValue) throw new Error('Invalid DeliveryDateTimeRange construction.');

        if (!this.arrivalMin || !this.arrivalMax || !this.arrivalType) return defaultValue;

        return DeliveryDateTimeRange.typeOf(this.arrivalType, this.arrivalMin, this.arrivalMax) ?? defaultValue;
    }

    set arrival(param: DeliveryDateTimeRange | null | undefined) {
        if (!param) {
            this.arrivalMin = '';
            this.arrivalMax = '';
            return;
        }

        const criteria = DateUtil.now().endOf('hour');
        if (!Validator.validateDateTimeRangeType(param, criteria).result) {
            this.arrivalMin = '';
            this.arrivalMax = '';
            return;
        }

        // 発日時との相対的な妥当性チェック
        if (!Validator.validateMultipleDateTimeRange(this.departure, param).result) {
            this.arrivalMin = '';
            this.arrivalMax = '';
            return;
        }

        const [min, max] = param.rawValuesAsString();
        this.arrivalMin = min;
        this.arrivalMax = max;
        this.arrivalType = param.type;
    }

    get arrivalDateTimeRange(): DateTimeRangePickerValue {
        return { min: this.arrivalMin, max: this.arrivalMax, type: this.arrivalType };
    }

    set arrivalDateTimeRange(value: DateTimeRangePickerValue) {
        const { min, max, type } = value;
        // 到着日時を書き換え
        this.arrivalMin = min ?? '';
        this.arrivalMax = max ?? '';
        this.arrivalType = type;
    }

    get arrivalPrefCode(): PrefectureEnumCode {
        return this.arrivalPref.code as PrefectureEnumCode;
    }

    set arrivalPrefCode(value: PrefectureEnumCode) {
        const changed = this.arrivalPref.code !== value;
        this.arrivalPref = { code: value };
        if (changed) {
            this.arrivalCity = '';
            this.arrivalAddress = '';
        }
    }

    get shapeCode(): BaggageShapeEnumCode | undefined {
        return this.shape?.code as BaggageShapeEnumCode;
    }

    set shapeCode(value: BaggageShapeEnumCode | undefined) {
        this.shape = BaggageShapeEnum.valueOf(value);
        // 荷姿の変更時の荷種の補完
        switch (this.shape?.code) {
            case BaggageShapeEnum.Palette.code:
                this.previousType = this.type.trim();
                this.type = 'パレット';
                break;
            case BaggageShapeEnum.Other.code:
                this.type = this.previousType;
                break;
            default:
                break;
        }
    }

    get baggageType(): string | undefined {
        return this.type;
    }

    set baggageType(value: string | undefined) {
        this.type = value ?? '';
        // 引っ越しという単語が含まれている場合は引っ越し案件に強制的に設定
        const isRelocate = BaggageUtil.includesRelocateWord(value);
        if (isRelocate) {
            this.category = { code: BaggageCategoryEnum.Relocation.code };
        } else if (this.category.code === BaggageCategoryEnum.Relocation.code) {
            this.category = { code: BaggageCategoryEnum.Normal.code };
        }
    }

    get temperatureZoneCode(): string {
        return this.temperatureZone.code ?? '';
    }

    set temperatureZoneCode(value: string) {
        this.temperatureZone = { code: value };
    }

    get paletteCountText(): string {
        return this.paletteCount ?? '';
    }

    set paletteCountText(value: string) {
        this.paletteCount = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    // Note: 本来不要なのだけど、antdのバリデーション処理をするために定義している
    // TODO: 要リファクタリング
    get paletteSize(): { paletteHeight: string | undefined, paletteWidth: string | undefined } {
        return {
            paletteHeight: this.paletteHeight,
            paletteWidth: this.paletteWidth,
        };
    }

    get paletteHeightText(): string {
        return this.paletteHeight ?? '';
    }

    set paletteHeightText(value: string) {
        this.paletteHeight = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    get paletteWidthText(): string {
        return this.paletteWidth ?? '';
    }

    set paletteWidthText(value: string) {
        this.paletteWidth = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    get totalCountText(): string {
        return this.totalCount ?? '';
    }

    set totalCountText(value: string) {
        this.totalCount = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    get totalVolumeText(): string {
        return this.totalVolume ?? '';
    }

    set totalVolumeText(value: string) {
        this.totalVolume = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    get totalWeightText(): string {
        return this.totalWeight ?? '';
    }

    set totalWeightText(value: string) {
        this.totalWeight = isNaN(Util.toNumber(value)) ? '' : Util.toDigits(value);
    }

    get loadingCode(): string | undefined {
        return this.loading?.code;
    }

    set loadingCode(value: string | undefined) {
        this.loading = BaggageHandlingTypeEnum.valueOf(value);
    }

    get unloadingCode(): string | undefined {
        return this.unloading?.code;
    }

    set unloadingCode(value: string | undefined) {
        this.unloading = BaggageHandlingTypeEnum.valueOf(value);
    }

    // Note: 本来不要なのだけど、antdのバリデーション処理をするために定義している
    // TODO: 要リファクタリング
    get truck(): { weight?: string, model?: string } {
        return {
            weight: this.truckWeight.code,
            model: this.truckModel.code,
        };
    }

    get truckWeightCode(): string {
        return this.truckWeight.code ?? '';
    }

    set truckWeightCode(value: string) {
        this.truckWeight = { code: value };
    }

    get truckModelCode(): string {
        return this.truckModel.code ?? '';
    }

    set truckModelCode(value: string) {
        this.truckModel = { code: value };
    }

    get truckHeightCode(): string | undefined {
        return this.truckHeight?.code;
    }

    set truckHeightCode(value: string | undefined) {
        this.truckHeight = value ? { code: value } : undefined;
    }

    get truckWidthCode(): string | undefined {
        return this.truckWidth?.code;
    }

    set truckWidthCode(value: string | undefined) {
        this.truckWidth = value ? { code: value } : undefined;
    }

    get largeTruckFlgCode(): string | undefined {
        if (this.largeTruckFlg == true) return 'true';
        if (this.largeTruckFlg == false) return 'false';
        return undefined;
    }

    set largeTruckFlgCode(value: string | undefined) {
        const toBoolean = (value: string | undefined) => {
            if (value?.toLowerCase() === 'true') return true;
            if (value?.toLowerCase() === 'false') return false;
            return undefined;
        };
        this.largeTruckFlg = toBoolean(value);
    }

    get truckEquipmentText(): string {
        return this.truckEquipment ?? '';
    }

    set truckEquipmentText(value: string) {
        this.truckEquipment = value;
    }

    get truckCountText(): string | number {
        return this.truckCount ?? '1';
    }

    set truckCountText(value: string | number) {
        this.truckCount = typeof value !== 'number' ? '' : value.toString();
    }

    get baggageFreight(): BaggageFreightValue {
        return new BaggageFreightValue(!this.negotiate ? Util.toNumber(this.freight ?? '0') : undefined);
    }

    set baggageFreight(value: BaggageFreightValue) {
        this.negotiate = value.isNegotiate;
        this.freight = value.value?.toString() ?? '';
    }

    get paymentDateValue(): DateValue | undefined {
        return this.paymentDate ? new DateValue(this.paymentDate) : undefined;
    }

    set paymentDateValue(value: DateValue | undefined) {
        this.paymentDate = value?.format('YYYY-MM-DD');
    }

    get descriptionText(): string {
        return this.description ?? '';
    }

    set descriptionText(value: string) {
        this.description = value;
    }

    get relocate(): boolean {
        return this.category.code == BaggageCategoryEnum.Relocation.code;
    }

    set relocate(value: boolean) {
        this.category.code =
            value || BaggageUtil.includesRelocateWord(this.type)
                ? BaggageCategoryEnum.Relocation.code
                : BaggageCategoryEnum.Normal.code;
    }

    get negotiationTypeCode(): NegotiationTypeEnumCode {
        return (NegotiationTypeEnum.valueOf(this.negotiationType?.code) ?? NegotiationTypeEnum.Phone).code;
    }

    set negotiationTypeCode(value: NegotiationTypeEnumCode) {
        this.negotiationType = NegotiationTypeEnum.valueOf(value);
    }

    static parseTruckCount(value: string): number | undefined {
        if (_.isEmpty(value)) return undefined;
        return Util.toNumber(value);
    }

    /**
     * 荷姿が指定されているか否か
     */
    get hasShape(): boolean {
        return !!this.shape;
    }

    /**
     * 車両指定に関係する項目のいずれかが登録されているか
     */
    get hasAnySpecifiedTruck(): boolean {
        return !_.isNil(this.truckHeight) || !_.isNil(this.truckWidth) || !_.isNil(this.largeTruckFlg);
    }

    /**
     * 荷姿がパレットであるか否か
     */
    get isShapePalette(): boolean {
        return this.shape?.code === BaggageShapeEnum.Palette.code;
    }

    /**
     * 荷姿がその他であるか否か
     */
    get isShapeOther(): boolean {
        return this.shape?.code === BaggageShapeEnum.Other.code;
    }

    /**
     * 複数台荷物であるか否か
     */
    get isMultipleTruckCount(): boolean {
        return Util.toNumber(this.truckCount) > 1;
    }

    get label(): { labelText?: string, labelColor?: string } {
        return {
            labelText: this.labelText,
            labelColor: this.labelColor
        };
    }

    set label(value: { labelText?: string, labelColor?: string }) {
        this.labelText = value.labelText;
        this.labelColor = value.labelColor;
    }

    /**
     * 成約予測が可能な状態か否か
     */
    get isReadyToPredict(): boolean {
        if (!this.truckModel) return false;
        if (!this.truckWeight) return false;
        if (this.type.length === 0) return false;
        if (!this.departurePref.code) return false;
        if (!this.arrivalPref.code) return false;
        if (!this.departureMin || !this.departureMax) return false;
        // noinspection RedundantIfStatementJS
        if (!this.arrivalMin || !this.arrivalMax) return false;

        return true;
    }

    clearSpecifiedTruck(): void {
        this.truckHeight = undefined;
        this.truckWidth = undefined;
        this.largeTruckFlg = undefined;
    }

    /**
     * 参考運賃が表示可能か否か
     */
    get canShowReferenceFreight(): boolean {
        return [
            this.departurePref.code,
            this.arrivalPref.code,
            this.truckWeight.code,
            this.truckModel.code,
        ].every(val => !_.isEmpty(val));
    }

    public normalize(): void {
        this.departureCity = this.departureCity?.trim();
        this.departureAddress = this.departureAddress?.trim();
        this.arrivalCity = this.arrivalCity?.trim();
        this.arrivalAddress = this.arrivalAddress?.trim();
        this.baggageType = this.baggageType?.trim();
        this.truckEquipmentText = this.truckEquipmentText.trim();
        this.descriptionText = this.descriptionText.trim();
        this.staffName = this.staffName?.trim();
        this.shipperName = this.shipperName?.trim();
        this.labelText = this.labelText?.trim();
    }

    freightMasterQuery(criteria: DateValue): BaggageFreightMasterQueryForm | undefined {
        if (!this.canShowReferenceFreight) return undefined;
        return new BaggageFreightMasterQueryForm({
            year: criteria.year,
            month: criteria.monthValue,
            departurePref: { code: this.departurePrefCode },
            arrivalPref: { code: this.arrivalPrefCode },
            truckWeight: { code: this.truckWeight.code ?? '' },
            truckModel: { code: this.truckModel.code ?? '' },
        });
    }

    /**
     * 入金予定日として選択できる範囲
     */
    get availablePaymentDateRange(): [dayjs.Dayjs, dayjs.Dayjs] {
        // 計算基準は着日、フォールバックとして発日を考慮
        const criteria = [this.arrivalMax, this.departureMax].find(_.negate(_.isEmpty)) ?? '';

        return AgreementUtil.availablePaymentDateRange(new DateValue(criteria).value, false);
    }

    /**
     * 入金予定日として選択できない日付であるか否かを取得します。
     */
    isDisabledPaymentDate = (currentDate: DateValue): boolean => {
        return !DateUtil.isIncluded(currentDate.value, this.availablePaymentDateRange);
    };

    /**
     * {@link BaggageRegisterForm}を生成します。
     */
    toForm(): BaggageRegisterForm {
        type THIS = BaggageRegisterFormModel;
        const omits: Array<keyof THIS> = ['negotiate', 'previousType'];

        const form = new BaggageRegisterForm(_.omit<THIS, keyof THIS>(this, omits));

        // 要相談なら運賃をクリア
        form.freight = this.negotiate ? '' : form.freight;
        // 荷姿：パレットなら個数、体積をクリア
        if (this.isShapePalette) {
            form.totalCount = undefined;
            form.totalVolume = undefined;
        }
        // 荷姿：その他ならパレット枚数（目安）、サイズをクリア
        if (this.isShapeOther) {
            form.paletteCount = undefined;
            form.paletteHeight = undefined;
            form.paletteWidth = undefined;
        }

        form.departurePref = { code: this.departureLocation.prefecture?.code };
        form.departureCity = this.departureLocation.city;
        form.departureAddress = this.departureLocation.address;

        form.arrivalPref = { code: this.arrivalLocation.prefecture?.code };
        form.arrivalCity = this.arrivalLocation.city;
        form.arrivalAddress = this.arrivalLocation.address;

        return form;
    }

    /**
     * {@link Baggage}から{@link BaggageRegisterFormModel}を生成します。
     */
    static of(baggage: Baggage): BaggageRegisterFormModel {
        const instance = new BaggageRegisterFormModel();
        // 1.発情報
        // 発日時は妥当性検査を経て設定
        instance.departure = DeliveryDateTimeRange.of(baggage.departureMin, baggage.departureMax);
        instance.departurePref = { code: baggage.departurePref.code.toString() };
        instance.departureCity = baggage.departureCity;
        instance.departureAddress = baggage.departureAddress;
        instance.departureLocation = new TmpBaggageLocationValue({
            prefecture: PrefectureEnum.valueOf(baggage.departurePref.code as PrefectureEnumCode),
            city: baggage.departureCity,
            address: baggage.departureAddress,
        });
        instance.loadingTimeNote = baggage.loadingTimeNote;
        // 2.着情報
        // 着日時は妥当性検査を経て設定
        instance.arrival = DeliveryDateTimeRange.of(baggage.arrivalMin, baggage.arrivalMax);
        instance.arrivalPref = { code: baggage.arrivalPref.code.toString() };
        instance.arrivalCity = baggage.arrivalCity;
        instance.arrivalAddress = baggage.arrivalAddress;
        instance.arrivalLocation = new TmpBaggageLocationValue({
            prefecture: PrefectureEnum.valueOf(baggage.arrivalPref.code as PrefectureEnumCode),
            city: baggage.arrivalCity,
            address: baggage.arrivalAddress,
        });
        instance.unloadingTimeNote = baggage.unloadingTimeNote;
        // 3.引っ越し
        instance.category = baggage.category;
        // 4.荷種
        instance.type = baggage.type;
        // 5.荷姿
        instance.shape = baggage.shape;
        // 6.パレット情報
        instance.paletteCount = baggage.paletteCount?.toString();
        instance.paletteHeight = baggage.paletteHeight?.toString();
        instance.paletteWidth = baggage.paletteWidth?.toString();
        // 7.その他情報
        instance.totalCount = baggage.totalCount?.toString();
        instance.totalVolume = baggage.totalVolume?.toString();
        // 8.総重量
        instance.totalWeight = baggage.totalWeight?.toString();
        // 9.ドライバー作業
        instance.loading = baggage.loading;
        instance.unloading = baggage.unloading;
        // 10.トラック情報
        instance.truckWeight = { code: baggage.truckWeight.code.toString() };
        instance.truckModel = { code: baggage.truckModel.code.toString() };
        instance.truckHeight = baggage.truckHeight;
        instance.truckWidth = baggage.truckWidth;
        instance.largeTruckFlg = baggage.largeTruckFlg;
        instance.truckEquipment = baggage.truckEquipment;
        // 11.台数 = デフォルト
        instance.share = baggage.share;
        // 12.フラグ情報
        instance.express = baggage.express;
        // 13.運賃
        instance.freight = baggage.freight ? baggage.freight.toString() : '';
        instance.highwayFareFlg = baggage.highwayFareFlg;
        // 14.担当者
        instance.staffName = baggage.staffName;
        // 15.備考
        instance.description = baggage.description;
        // 16.商談中 = デフォルト
        // 17.要相談
        instance.negotiate = !baggage.freight;
        // 18.変更前荷種 = デフォルト
        // 19.入金予定日
        instance.paymentDate = baggage.paymentDate;
        // 20.連絡方法
        instance.negotiationType = baggage.negotiationType;
        return instance;
    }
}

export type BaggageUpdateApiForm = {
    circleId: number;
    departureMin: string;
    departureMax: string;
    departurePref: Enum;
    departureCity: string;
    departureAddress?: string;
    loadingTimeNote?: string;
    arrivalMin: string;
    arrivalMax: string;
    arrivalPref: Enum;
    arrivalCity: string;
    arrivalAddress?: string;
    unloadingTimeNote?: string;
    category: Enum<BaggageCategoryEnumCode | string>;
    type: string;
    shape: Enum<BaggageShapeEnumCode | ''>;
    paletteCount?: string;
    paletteHeight?: string;
    paletteWidth?: string;
    totalCount?: string;
    totalVolume?: string;
    totalWeight?: string;
    loading?: Enum<BaggageHandlingTypeEnumCode>;
    unloading?: Enum<BaggageHandlingTypeEnumCode>;
    temperatureZone: Enum<BaggageTemperatureZoneEnumCode>;
    truckWeight: Enum;
    truckModel: Enum;
    truckHeight?: Enum;
    truckWidth?: Enum;
    largeTruckFlg?: boolean;
    truckEquipment?: string;
    share: boolean;
    express: boolean;
    freight: string;
    highwayFareFlg: boolean;
    staffName: string;
    description?: string;
    paymentDate?: string;
    traboxBaggageId?: number;
    shipperName?: string;
    labelText?: string;
    labelColor?: string;

    // TODO: 廃止予定
    // 商談中オンオフは別APIで処理しているので、これは本来不要なもの
    // API側を修正してから削除する
    underNegotiation: boolean;
};

// 荷姿詳細
type ShapeDetail = {
    // パレット詳細
    'BS1': {
        type?: string;
        paletteCount?: string;
        paletteHeight?: string;
        paletteWidth?: string;
    }
    // その他詳細
    'BS2': {
        type?: string;
        totalCount?: string;
        totalVolume?: string;
    }
};

// TODO: リファクタリングが終わったら、BaggageUpdateFormModelへリネームする
export type NewBaggageUpdateFormModel = {
    // 部屋ID
    circleId: number | undefined;
    // 発日時
    departureDateTimeRange?: DeliveryDateTimeRange;
    // 着日時
    arrivalDateTimeRange?: DeliveryDateTimeRange;
    // 出発地
    departurePrefCode?: PrefectureEnumCode;
    departureCity?: string;
    departureAddress?: string;
    // 到着地
    arrivalPrefCode?: PrefectureEnumCode;
    arrivalCity?: string;
    arrivalAddress?: string;
    // 積み時間
    loadingTimeNote?: string;
    // 卸し時間
    unloadingTimeNote?: string;
    // 荷姿
    shape?: BaggageShapeEnumCode;
    // 荷姿詳細
    shapeDetail: ShapeDetail;
    // 温度帯
    temperatureZone?: BaggageTemperatureZoneEnumCode;
    // 荷種
    readonly type: string | undefined;
    // パレット
    readonly paletteCount?: string;
    readonly paletteHeight?: string;
    readonly paletteWidth?: string;
    // バリデーション用
    readonly paletteSize: {
        paletteHeight: string | undefined;
        paletteWidth: string | undefined;
    },
    // 個数
    readonly totalCount?: string;
    // 体積
    readonly totalVolume?: string;
    // 総重量
    totalWeight?: string;
    // 荷役
    loadingOperation?: BaggageHandlingTypeEnumCode;
    unloadingOperation?: BaggageHandlingTypeEnumCode;
    // 希望トラック
    truckType: {
        truckWeight: TruckWeightEnumCode;
        truckModel: TruckModelEnumCode;
    },
    // トラックサイズ
    truckHeight?: TruckHeightEnumCode;
    truckWidth?: TruckWidthEnumCode;
    // 大型トラックの可否
    largeTruckAvailability?: LargeTruckAvailability;
    truckEquipment?: string;
    // 運賃
    // TODO: BaggageFreightInputコンポーネントがちょっと複雑なのでリファクタリングしたほうがよさそう
    // こんなTypeを使えばシンプルにできると思う `{ decided: true, price?: number } | { decide: false }`
    baggageFreight: BaggageFreightValue,
    highwayFarePaymentOption?: BaggageHighwayFarePaymentOption;
    // 入金日
    paymentDate?: DateValue;
    // 運送区分
    category?: BaggageCategoryEnumCode;
    // 積み合い
    share: boolean;
    // 至急
    express: boolean;
    // 担当者
    staffName: string;
    // 備考
    description?: string;
    // TODO: 商談中の変更は別APIで行っているので、これは削除する（API側の修正が必要）
    // 商談中
    underNegotiation: boolean;
    // トラボックス荷物ID
    traboxBaggageId: number | undefined;
    // 荷主名
    shipperName: string | undefined;
    // ラベル
    label: { labelText?: string, labelColor?: string };
};

// TODO: Deprecated リファクタリング後に削除する
export class BaggageUpdateForm {
    // 1.発情報
    departureMin: string;
    departureMax: string;
    departurePref: Enum;
    departureCity: string;
    departureAddress?: string;
    loadingTimeNote?: string;
    // 2.着情報
    arrivalMin: string;
    arrivalMax: string;
    arrivalPref: Enum;
    arrivalCity: string;
    arrivalAddress?: string;
    unloadingTimeNote?: string;
    // 3.引っ越し
    category: Enum<BaggageCategoryEnumCode | string>;
    // 4.荷種
    type: string;
    // 5.荷姿
    shape: Enum<BaggageShapeEnumCode | ''>;
    // 6.パレット情報
    paletteCount?: string;
    paletteHeight?: string;
    paletteWidth?: string;
    // 7.その他情報
    totalCount?: string;
    totalVolume?: string;
    // 8.総重量
    totalWeight?: string;
    // 9.荷役
    loading?: Enum<BaggageHandlingTypeEnumCode>;
    unloading?: Enum<BaggageHandlingTypeEnumCode>;
    // 10.トラック情報
    truckWeight: Enum;
    truckModel: Enum;
    truckHeight?: Enum;
    truckWidth?: Enum;
    largeTruckFlg?: boolean;
    truckEquipment?: string;
    // 11.フラグ情報
    share: boolean;
    express: boolean;
    // 12.運賃
    freight: string;
    highwayFareFlg: boolean;
    // 13.担当者
    staffName: string;
    // 14.備考
    description?: string;
    // 15.商談中
    underNegotiation: boolean;
    // 16.入金予定日
    paymentDate?: string;
    // 17.受付方法
    negotiationType?: Enum<NegotiationTypeEnum>;
    // 18.温度帯
    temperatureZone: Enum<BaggageTemperatureZoneEnumCode>;

    /**
     * コンストラクタ
     */
    constructor(param: Partial<BaggageUpdateForm> | null = null) {
        // 1.発情報
        this.departureMin = param?.departureMin ?? '';
        this.departureMax = param?.departureMax ?? '';
        this.departurePref = { code: param?.departurePref?.code ?? '' };
        this.departureCity = param?.departureCity ?? '';
        this.departureAddress = param?.departureAddress ?? '';
        this.loadingTimeNote = param?.loadingTimeNote;
        // 2.着情報
        this.arrivalMin = param?.arrivalMin ?? '';
        this.arrivalMax = param?.arrivalMax ?? '';
        this.arrivalPref = { code: param?.arrivalPref?.code ?? '' };
        this.arrivalCity = param?.arrivalCity ?? '';
        this.arrivalAddress = param?.arrivalAddress ?? '';
        this.unloadingTimeNote = param?.unloadingTimeNote;
        // 3.引っ越し
        this.category = { code: param?.category?.code ?? '' };
        // 4.荷種
        this.type = param?.type ?? '';
        // 5.荷姿
        this.shape = { code: param?.shape?.code ?? '' };
        // 6.パレット情報
        this.paletteCount = param?.paletteCount?.toString();
        this.paletteHeight = param?.paletteHeight?.toString();
        this.paletteWidth = param?.paletteWidth?.toString();
        // 7.その他情報
        this.totalCount = param?.totalCount?.toString();
        this.totalVolume = param?.totalVolume?.toString();
        // 8.総重量
        this.totalWeight = param?.totalWeight?.toString();
        // 9.荷役
        this.loading = param?.loading ? { code: param.loading.code } : undefined;
        this.unloading = param?.unloading ? { code: param.unloading.code } : undefined;
        // 10.トラック情報
        this.truckWeight = { code: param?.truckWeight?.code ?? '' };
        this.truckModel = { code: param?.truckModel?.code ?? '' };
        this.truckHeight = param?.truckHeight;
        this.truckWidth = param?.truckWidth;
        this.largeTruckFlg = param?.largeTruckFlg;
        this.truckEquipment = param?.truckEquipment;
        // 11.フラグ情報
        this.share = param?.share ?? false;
        this.express = param?.express ?? false;
        // 12.運賃
        this.freight = param?.freight?.toString() ?? '';
        this.highwayFareFlg = param?.highwayFareFlg ?? false;
        // 13.担当者
        this.staffName = param?.staffName ?? '';
        // 14.備考
        this.description = param?.description ?? '';
        // 15.商談中
        this.underNegotiation = param?.underNegotiation ?? false;
        // 16.入金予定日
        this.paymentDate = param?.paymentDate;
        // 17.受付方法
        this.negotiationType = param?.negotiationType ? { code: param.negotiationType.code } : undefined;
        // 18.温度帯
        this.temperatureZone = param?.temperatureZone ?
            { code: param?.temperatureZone?.code ?? '' } : { code: BaggageTemperatureZoneEnum.Ambient.code };
    }
}

// TODO: Deprecated リファクタリング後に削除する
export class BaggageUpdateFormModel extends BaggageUpdateForm {
    // TODO リファクタリング（BaggageShapeEditクラスの責務にしたほうが良さそう）
    // 16.変更前荷種
    previousType: string;

    /**
     * 荷姿がパレットであるか否か
     */
    get isShapePalette(): boolean {
        return this.shape?.code === BaggageShapeEnum.Palette.code;
    }

    /**
     * 荷姿がその他であるか否か
     */
    get isShapeOther(): boolean {
        return this.shape?.code === BaggageShapeEnum.Other.code;
    }

    /**
     * 成約予測が可能な状態か否か
     */
    get isReadyToPredict(): boolean {
        if (!this.truckModel) return false;
        if (!this.truckWeight) return false;
        if (this.type.length === 0) return false;
        if (!this.departurePref.code) return false;
        if (!this.arrivalPref.code) return false;
        if (!this.departureMin || !this.departureMax) return false;
        // noinspection RedundantIfStatementJS
        if (!this.arrivalMin || !this.arrivalMax) return false;

        return true;
    }

    /**
     * コンストラクタ
     */
    constructor(param: Partial<BaggageUpdateFormModel> | null = null) {
        super(param);
        // 16.変更前荷種
        this.previousType = param?.type ?? '';
    }

    /**
     * {@link BaggageUpdateForm}を生成します。
     */
    toForm(): BaggageUpdateForm {
        type THIS = BaggageUpdateFormModel;
        const omits: Array<keyof THIS> = ['previousType'];

        const form = new BaggageUpdateForm(_.omit<THIS, keyof THIS>(this, omits));

        // 荷姿：パレットなら個数、体積をクリア
        if (this.isShapePalette) {
            form.totalCount = undefined;
            form.totalVolume = undefined;
        }
        // 荷姿：その他ならパレット枚数（目安）、サイズをクリア
        if (this.isShapeOther) {
            form.paletteCount = undefined;
            form.paletteHeight = undefined;
            form.paletteWidth = undefined;
        }

        return form;
    }

    /**
     * {@link Baggage}から{@link BaggageUpdateFormModel}を生成します。
     */
    static of(baggage: Baggage): BaggageUpdateFormModel {
        const instance = new BaggageUpdateFormModel();
        // 1.発情報
        instance.departureMin = baggage.departureMin;
        instance.departureMax = baggage.departureMax;
        instance.departurePref = { code: baggage.departurePref.code };
        instance.departureCity = baggage.departureCity;
        instance.departureAddress = baggage.departureAddress ?? '';
        instance.loadingTimeNote = baggage.loadingTimeNote;
        // 2.着情報
        instance.arrivalMin = baggage.arrivalMin;
        instance.arrivalMax = baggage.arrivalMax;
        instance.arrivalPref = { code: baggage.arrivalPref.code };
        instance.arrivalCity = baggage.arrivalCity;
        instance.arrivalAddress = baggage.arrivalAddress ?? '';
        instance.unloadingTimeNote = baggage.unloadingTimeNote;
        // 3.引っ越し
        instance.category = { code: baggage.category.code };
        // 4.荷種
        instance.type = baggage.type;
        // 5.荷姿
        instance.shape = { code: baggage.shape.code };
        // 6.パレット情報
        instance.paletteCount = baggage.paletteCount?.toString();
        instance.paletteHeight = baggage.paletteHeight?.toString();
        instance.paletteWidth = baggage.paletteWidth?.toString();
        // 7.その他情報
        instance.totalCount = baggage.totalCount?.toString();
        instance.totalVolume = baggage.totalVolume?.toString();
        // 8.総重量
        instance.totalWeight = baggage.totalWeight?.toString();
        // 9.荷役
        instance.loading = baggage.loading ? { code: baggage.loading.code } : undefined;
        instance.unloading = baggage.unloading ? { code: baggage.unloading.code } : undefined;
        // 10.トラック情報
        instance.truckWeight = { code: baggage.truckWeight.code };
        instance.truckModel = { code: baggage.truckModel.code };
        instance.truckHeight = baggage.truckHeight;
        instance.truckWidth = baggage.truckWidth;
        instance.largeTruckFlg = baggage.largeTruckFlg;
        instance.truckEquipment = baggage.truckEquipment;
        // 11.フラグ情報
        instance.share = baggage.share;
        instance.express = baggage.express;
        // 12.運賃
        instance.freight = baggage.freight?.toString() ?? '';
        instance.highwayFareFlg = baggage.highwayFareFlg;
        // 13.担当者
        instance.staffName = baggage.staffName;
        // 14.備考
        instance.description = baggage.description ?? '';
        // 15.商談中
        instance.underNegotiation = baggage.underNegotiation;
        // 16.変更前荷種
        instance.previousType = baggage.type;
        // 17.入金予定日
        instance.paymentDate = baggage.paymentDate;

        return instance;
    }
}

export type BaggageListType = 'OPENED' | 'CANCEL';

// TODO: BaggageListFormを利用している箇所のリファクタリングが終わったら、Genericをやめて固定の型を使う（BaggageListFormModelと同じ型になるようにする）
export type BaggageListForm<DateTime = string, SK = string, SO = string> = {
    id?: number;
    status: { code: BaggageListType };
    departurePref?: Array<Enum>;
    arrivalPref?: Array<Enum>;
    departureFrom?: DateTime;
    departureTo?: DateTime;
    truckWeight?: Array<Enum>;
    truckModel?: Array<Enum>;
    staffName?: string;
    pageNo?: number;
    pageSize?: number;
    sortKey: SK;
    sortOrder: SO;
};

// BaggageListFormを利用している箇所を消し終えるまでの一時利用
export type BaggageListFormModel = BaggageListForm<DateTimeValue, BaggageSortKeyCode, SortOrder>;

/**
 * マイ荷物検索で指定可能な日付かをチェックする。
 */
export const baggageListFormDateAvailability = (date: DateValue): boolean => {
    // 翌年末までが有効な範囲
    return date.isSameOrBefore(DateValue.today().add(1, 'year').endOf('year'));
};

export interface BaggageSearchForm {
    circleId?: number;
    id?: number;
    departureFrom?: string;
    departureTo?: string;
    departurePref?: Array<Enum>;
    arrivalFrom?: string;
    arrivalTo?: string;
    arrivalPref?: Array<Enum>;
    category?: Enum;
    type?: string;
    truckWeight?: Array<Enum>;
    truckModel?: Array<Enum>;
    share?: boolean;
    pageNo?: number;
    pageSize?: number;
    freight?: string;
    excludeUndecidedFreight: boolean;
    excludeUndecidedTruckWeight: boolean;
    excludeUndecidedTruckModel: boolean;
    onlyStatusOpen: boolean;
    sortKey: string;
    sortOrder: string;
}

export class BaggageSearchFormModel implements BaggageSearchForm, FormValidatable<BaggageSearchFormModel> {
    circleId?: number;
    id?: number;
    departureFrom?: string;
    departureTo?: string;
    departurePref?: Array<PrefectureEnum>;
    arrivalFrom?: string;
    arrivalTo?: string;
    arrivalPref?: Array<PrefectureEnum>;
    category?: BaggageCategoryEnum;
    type?: string;
    truckWeight?: Array<TruckWeightEnum>;
    truckModel?: Array<TruckModelEnum>;
    share?: boolean;
    pageNo?: number;
    pageSize?: number;
    _freight?: string;
    excludeUndecidedFreight: boolean;
    excludeUndecidedTruckWeight: boolean;
    excludeUndecidedTruckModel: boolean;
    onlyStatusOpen: boolean;
    sortKey: string;
    sortOrder: 'ASC' | 'DESC';

    constructor(param: Partial<BaggageSearchForm> = {}) {
        this.circleId = param.circleId;
        this.id = undefined;
        this.departureFrom = param.departureFrom ?? '';
        this.departureTo = param.departureTo ?? '';
        if (param.departurePref && !_.isEmpty(param.departurePref)) {
            this.departurePref = param.departurePref.map(each => Util.requireNotNull(PrefectureEnum.valueOf(each.code as PrefectureEnumCode)));
        } else {
            this.departurePref = [];
        }
        this.arrivalFrom = param.arrivalFrom ?? '';
        this.arrivalTo = param.arrivalTo ?? '';
        if (param.arrivalPref && !_.isEmpty(param.arrivalPref)) {
            this.arrivalPref = param.arrivalPref.map(each => Util.requireNotNull(PrefectureEnum.valueOf(each.code as PrefectureEnumCode)));
        } else {
            this.arrivalPref = [];
        }
        if (param.category && !_.isEmpty(param.category.code)) {
            this.category = Util.requireNotNull(BaggageCategoryEnum.valueOf(param.category.code as BaggageCategoryEnumCode));
        } else {
            this.category = undefined;
        }
        this.type = param.type ?? '';
        if (param.truckWeight && !_.isEmpty(param.truckWeight)) {
            this.truckWeight = param.truckWeight.map(each => Util.requireNotNull(TruckWeightEnum.valueOf(each.code as TruckWeightEnumCode)));
        } else {
            this.truckWeight = [];
        }
        if (param.truckModel && !_.isEmpty(param.truckModel)) {
            this.truckModel = param.truckModel.map(each => Util.requireNotNull(TruckModelEnum.valueOf(each.code as TruckModelEnumCode)));
        } else {
            this.truckModel = [];
        }
        this.share = param.share;
        this.pageNo = param.pageNo ?? 1;
        this.pageSize = param.pageSize ?? 20;
        this.freight = param.freight;
        this.excludeUndecidedFreight = param.excludeUndecidedFreight ?? false;
        this.excludeUndecidedTruckWeight = param.excludeUndecidedTruckWeight ?? false;
        this.excludeUndecidedTruckModel = param.excludeUndecidedTruckModel ?? false;
        this.onlyStatusOpen = param.onlyStatusOpen ?? false;
        this.sortKey = param.sortKey ?? 'DEPARTURE';
        this.sortOrder = param.sortOrder === 'DESC' ? 'DESC' : 'ASC';
    }

    get departureFromDate(): DateValue | undefined {
        return this.departureFrom ? new DateValue(this.departureFrom) : undefined;
    }

    set departureFromDate(value: DateValue | undefined) {
        if (value) {
            this.departureFrom = value.format('YYYY-MM-DD') + ' 00:00:00';
        } else {
            this.departureFrom = '';
        }
    }

    get departureToDate(): DateValue | undefined {
        return this.departureTo ? new DateValue(this.departureTo) : undefined;
    }

    set departureToDate(value: DateValue | undefined) {
        if (value) {
            this.departureTo = value.format('YYYY-MM-DD') + ' 23:59:59';
        } else {
            this.departureTo = '';
        }
    }

    get departurePrefCode(): PrefectureEnumCode | PrefectureEnumCode[] | undefined {
        return (this.departurePref ?? []).map(each => each.code);
    }

    set departurePrefCode(value: PrefectureEnumCode | PrefectureEnumCode[] | undefined) {
        if (_.isNil(value) || _.isEmpty(value)) {
            this.departurePref = [];
            return;
        }
        if (_.isArray(value)) {
            this.departurePref = value.map(each => Util.requireNotNull(PrefectureEnum.valueOf(each)));
            return;
        }
        this.departurePref = [Util.requireNotNull(PrefectureEnum.valueOf(value))];
    }

    get arrivalFromDate(): DateValue | undefined {
        return this.arrivalFrom ? new DateValue(this.arrivalFrom) : undefined;
    }

    set arrivalFromDate(value: DateValue | undefined) {
        if (value) {
            this.arrivalFrom = value.format('YYYY-MM-DD') + ' 00:00:00';
        } else {
            this.arrivalFrom = '';
        }
    }

    get arrivalToDate(): DateValue | undefined {
        return this.arrivalTo ? new DateValue(this.arrivalTo) : undefined;
    }

    set arrivalToDate(value: DateValue | undefined) {
        if (value) {
            this.arrivalTo = value.format('YYYY-MM-DD') + ' 23:59:59';
        } else {
            this.arrivalTo = '';
        }
    }

    get arrivalPrefCode(): PrefectureEnumCode | PrefectureEnumCode[] | undefined {
        return (this.arrivalPref ?? []).map(each => each.code);
    }

    set arrivalPrefCode(value: PrefectureEnumCode | PrefectureEnumCode[] | undefined) {
        if (_.isNil(value) || _.isEmpty(value)) {
            this.arrivalPref = [];
            return;
        }
        if (_.isArray(value)) {
            this.arrivalPref = value.map(each => Util.requireNotNull(PrefectureEnum.valueOf(each)));
            return;
        }
        this.arrivalPref = [Util.requireNotNull(PrefectureEnum.valueOf(value))];
    }

    get freight(): string {
        return this._freight ?? '';
    }

    set freight(value: string | undefined) {
        this._freight = value ? Util.parseFreightString(value) : '';
    }

    get truckWeightCode(): TruckWeightEnumCode[] {
        return this.truckWeight?.map((each) => each.code) || [];
    }

    set truckWeightCode(value: TruckWeightEnumCode[]) {
        if (_.isEmpty(value)) {
            this.truckWeight = [];
        } else {
            this.truckWeight = value.map(each => Util.requireNotNull(TruckWeightEnum.valueOf(each)));
        }
    }

    get truckModelCode(): TruckModelEnumCode[] {
        return this.truckModel?.map((each) => each.code) || [];
    }

    set truckModelCode(value: TruckModelEnumCode[]) {
        if (_.isEmpty(value)) {
            this.truckModel = [];
        } else {
            this.truckModel = value.map(each => Util.requireNotNull(TruckModelEnum.valueOf(each)));
        }
    }

    get onlyShare(): boolean {
        return this.share ?? false;
    }

    set onlyShare(value: boolean) {
        if (value) {
            this.share = true;
        } else {
            this.share = undefined;
        }
    }

    get excludeShare(): boolean {
        return this.share === false;
    }

    set excludeShare(value: boolean) {
        if (value) {
            this.share = false;
        } else {
            this.share = undefined;
        }
    }

    get onlyRelocation(): boolean {
        return this.category?.code === BaggageCategoryEnum.Relocation.code;
    }

    set onlyRelocation(value: boolean) {
        if (value) {
            this.category = BaggageCategoryEnum.Relocation;
        } else {
            this.category = undefined;
        }
    }

    get excludeRelocation(): boolean {
        return this.category?.code === BaggageCategoryEnum.Normal.code;
    }

    set excludeRelocation(value: boolean) {
        if (value) {
            this.category = BaggageCategoryEnum.Normal;
        } else {
            this.category = undefined;
        }
    }

    get baggageId(): string {
        return this.id?.toString() ?? '';
    }

    set baggageId(value: string) {
        const id = Util.toNumber(value);
        this.id = id > 0 ? id : undefined;
    }

    applyCondition(condition: BaggageSearchCondition): void {
        this.circleId = condition.circleId;
        this.departurePref = condition.departurePref.map(each => Util.requireNotNull(PrefectureEnum.valueOf(each.code)));
        this.arrivalPref = condition.arrivalPref.map(each => Util.requireNotNull(PrefectureEnum.valueOf(each.code)));
        this.category = condition.category ? BaggageCategoryEnum.valueOf(condition.category.code) : undefined;
        this.truckWeight = condition.truckWeight.map(each => Util.requireNotNull(TruckWeightEnum.valueOf(each.code as TruckWeightEnumCode)));
        this.truckModel = condition.truckModel.map(each => Util.requireNotNull(TruckModelEnum.valueOf(each.code as TruckModelEnumCode)));
        this.share = condition.share;
        this.freight = condition.freight?.toString();
        this.excludeUndecidedFreight = condition.excludeUndecidedFreight;
        this.excludeUndecidedTruckWeight = condition.excludeUndecidedTruckWeight;
        this.excludeUndecidedTruckModel = condition.excludeUndecidedTruckModel;
        this.pageNo = 1;
    }

    applyTruck(truck: Truck): void {
        if (truck.truckModel === TruckModelEnum.WingOrFlat) {
            this.truckModel = TruckModelEnum.wingOrFlatValues;
        } else if (truck.truckModel === TruckModelEnum.WingOrVan) {
            this.truckModel = TruckModelEnum.wingOrVanValues;
        } else {
            this.truckModel = [Util.requireNotNull(TruckModelEnum.valueOf(truck.truckModel.code as TruckModelEnumCode))];
        }

        const truckWeight = Util.requireNotNull(TruckWeightEnum.valueOf(truck.truckWeight.code as TruckWeightEnumCode));
        this.truckWeight = TruckWeightEnum.valuesLessThanOrEqualTo(truckWeight);

        const parseFormat = 'YYYY-MM-DD HH:mm:ss';
        this.departureFrom = (new DateTimeValue(truck.departureMin)).startOf('day').format(parseFormat);
        this.departureTo = (new DateTimeValue(truck.arrivalMin)).endOf('day').format(parseFormat);

        this.arrivalFrom = (new DateTimeValue(truck.departureMin)).startOf('day').format(parseFormat);
        this.arrivalTo = (new DateTimeValue(truck.arrivalMin)).endOf('day').format(parseFormat);

        this.departurePref = [Util.requireNotNull(PrefectureEnum.valueOf(truck.departurePrefecture.code as PrefectureEnumCode))];
        this.arrivalPref = [Util.requireNotNull(PrefectureEnum.valueOf(truck.arrivalPrefecture.code as PrefectureEnumCode))];
    }

    validate(): ValidateResult {
        return Validator.validateDateRangeForSearch(
            this.departureFrom,
            this.departureTo,
            this.arrivalFrom,
            this.arrivalTo
        );
    }

    validator(): FormValidator<BaggageSearchFormModel> {
        return {
            freight: [{ required: false }],
            baggageId: [{ required: false }],
        };
    }

    toJSON(): any {
        return JsonUtil.serialize(this);
    }
}

// よく使う検索条件
export interface BaggageSearchCondition {
    id: number;
    companyId: number;
    memberId: number;
    circleId?: number;
    departurePref: Array<Enum<PrefectureEnumCode>>;
    arrivalPref: Array<Enum<PrefectureEnumCode>>;
    category?: Enum<BaggageCategoryEnumCode>;
    truckWeight: Array<Enum>;
    truckModel: Array<Enum>;
    share?: boolean;
    freight?: number;
    excludeUndecidedFreight: boolean;
    excludeUndecidedTruckWeight: boolean;
    excludeUndecidedTruckModel: boolean;
    notification: boolean;
}

export class BaggageSearchConditionRegisterForm {
    circleId?: number;
    departurePref?: Array<Enum>;
    arrivalPref?: Array<Enum>;
    category?: Enum;
    truckWeight?: Array<Enum>;
    truckModel?: Array<Enum>;
    share?: boolean;
    freight?: number;
    excludeUndecidedFreight?: boolean;
    excludeUndecidedTruckWeight?: boolean;
    excludeUndecidedTruckModel?: boolean;

    isValid(): boolean {
        return (
            this.circleId !== undefined ||
            (this.departurePref ?? []).length > 0 ||
            (this.arrivalPref ?? []).length > 0 ||
            this.category !== undefined ||
            (this.truckWeight ?? []).length > 0 ||
            (this.truckModel ?? []).length > 0 ||
            this.share !== undefined ||
            this.freight !== undefined ||
            this.excludeUndecidedFreight !== undefined ||
            this.excludeUndecidedTruckWeight !== undefined ||
            this.excludeUndecidedTruckModel !== undefined
        );
    }

    static fromCondition(condition: BaggageSearchForm): BaggageSearchConditionRegisterForm {
        const result = new BaggageSearchConditionRegisterForm();

        // 部屋
        if (condition.circleId) {
            result.circleId = condition.circleId;
        }
        // 発地
        if (condition.departurePref && condition.departurePref.length > 0) {
            result.departurePref = condition.departurePref;
        }
        // 着地
        if (condition.arrivalPref && condition.arrivalPref.length > 0) {
            result.arrivalPref = condition.arrivalPref;
        }
        // 種別
        if (condition.category && condition.category.code) {
            result.category = condition.category;
        }
        // 重量
        if (condition.truckWeight && condition.truckWeight.length > 0) {
            result.truckWeight = condition.truckWeight;
        }
        // 車種
        if (condition.truckModel && condition.truckModel.length > 0) {
            result.truckModel = condition.truckModel;
        }
        // 積合
        if (!_.isNil(condition.share)) {
            result.share = condition.share;
        }
        // 運賃
        if (condition.freight && condition.freight.length > 0) {
            result.freight = Number(condition.freight);
        }
        // 運賃の要相談を除く
        if (condition.excludeUndecidedFreight) {
            result.excludeUndecidedFreight = condition.excludeUndecidedFreight;
        }
        // 重量の問わず除く
        if (condition.excludeUndecidedTruckWeight) {
            result.excludeUndecidedTruckWeight = condition.excludeUndecidedTruckWeight;
        }
        // 車種の問わず除く
        if (condition.excludeUndecidedTruckModel) {
            result.excludeUndecidedTruckModel = condition.excludeUndecidedTruckModel;
        }

        return result;
    }
}

export interface BaggageSearchConditionNotificationUpdateForm {
    notification?: boolean;
}

export interface BaggageSearchDetailDrawerDetailListTableData {
    key: string
    item: string,
    value: string[],
}

// 既読

export interface BaggageReadQueryForm {
    ids: Array<number>;
}

export interface BaggageReadQuery {
    ids: Array<number>;
}

export interface RecentBaggageListForm {
    pageNo?: number;
    pageSize?: number;
}

// お気に入り荷物

export interface FavoriteBaggageQueryForm {
    ids: Array<number>;
}

export interface FavoriteBaggageListForm {
    pageNo?: number;
    pageSize?: number;
}

export interface FavoriteBaggageQuery {
    ids: Array<number>;
}

export interface FavoriteBaggageCount {
    count: number;
}

// 荷物詳細の閲覧会員数

export interface BaggageViewMemberCount {
    baggageId: number;
    memberCount: number;
}

export interface BaggageSearchExcludedCompany {
    id: number;
    name: string;
}

export interface BaggageSearchContainer {
    // 検索中か否か
    searching: boolean;
    // 入力中の検索条件
    condition: BaggageSearchForm;
    // 自動検索のintervalId
    autoSearchIntervalId?: number;
    // 前回の検索日時
    lastSearchDatetime?: dayjs.Dayjs;
    // 検索結果
    result?: BaggageSearchResult;
}

export type BaggageSearchConditionSaveError = 'REACHED_LIMIT' | 'MISSING_FIELDS' | 'FAILED_TO_SAVE' | 'LOADING';

/**
 * 荷物保存状態
 * - Unmarked: 未保存
 * - Marked: 保存済み
 * - Unmarking: 保存済みをキャンセル中
 */
export type BaggageFavoriteState = 'Unmarked' | 'Marked' | 'Unmarking';

/**
 * 荷物運賃取得フォーム
 */
export class BaggageFreightMasterQueryForm {
    year: number;
    month: number;
    departurePref: { code: string };
    arrivalPref: { code: string };
    truckWeight: { code: string };
    truckModel: { code: string };

    constructor(param: ExcludeMethods<BaggageFreightMasterQueryForm>) {
        this.year = param.year;
        this.month = param.month;
        this.departurePref = { code: param.departurePref.code };
        this.arrivalPref = { code: param.arrivalPref.code };
        this.truckWeight = { code: param.truckWeight.code };
        this.truckModel = { code: param.truckModel.code };
    }

    static newInstance(baggage: Baggage): BaggageFreightMasterQueryForm {
        const { entryTm } = baggage;

        // 1年前の同月
        const entryDateTime = new DateTimeValue(entryTm);
        const lastYear = entryDateTime.subtract(1, 'year');

        return new BaggageFreightMasterQueryForm({
            ...baggage,
            year: lastYear.year,
            month: lastYear.monthValue,
        });
    }
}

/**
 * 荷物運賃リスト取得フォーム
 */
export class BaggageFreightMasterListForm {
    conditions: BaggageFreightMasterQueryForm[];

    constructor(param: ExcludeMethods<BaggageFreightMasterQueryForm>[]) {
        this.conditions = _.cloneDeep(param);
    }
}

/**
 * 荷物運賃マスタ
 */
export class BaggageFreightMaster {
    year: number;
    month: number;
    departurePref: Enum;
    arrivalPref: Enum;
    truckWeight: Enum;
    truckModel: Enum;
    freight: number;

    constructor(param: ExcludeMethods<BaggageFreightMaster>) {
        this.year = param.year;
        this.month = param.month;
        this.departurePref = { code: param.departurePref.code };
        this.arrivalPref = { code: param.arrivalPref.code };
        this.truckWeight = { code: param.truckWeight.code };
        this.truckModel = { code: param.truckModel.code };
        this.freight = param.freight;
    }

    match(input: Omit<ExcludeMethods<BaggageFreightMaster>, 'freight'>): boolean {
        return this.year === input.year
            && this.month === input.month
            && this.departurePref.code === input.departurePref.code
            && this.arrivalPref.code === input.arrivalPref.code
            && this.truckWeight.code === input.truckWeight.code
            && this.truckModel.code === input.truckModel.code;
    }

    matchFor(input: Baggage): boolean {
        const lastYear = DateUtil.parseDatetimeText(input.entryTm).subtract(1, 'year');
        return this.match({
            ...input,
            year: lastYear.year(),
            month: lastYear.month() + 1,
        });
    }

    format(): string {
        return this.freight <= 0 ? '' : `${ Util.formatNumber(this.freight) }円`;
    }
}

export class BaggageRecommendReturnsCount {
    id: number;
    count: number;

    constructor(param: ExcludeMethods<BaggageRecommendReturnsCount>) {
        this.id = param.id;
        this.count = param.count;
    }
}

export interface BaggageRecommendReturnsCountsForm {
    ids: Array<number>;
}

export class BaggageRecommendReturn {
    baggage: Baggage;
    initialRoute: number[][];
    toReturnRoute: number[][];
    returnRoute: number[][];
    fromReturnRoute: number[][];

    constructor(param: ExcludeMethods<BaggageRecommendReturn>) {
        this.baggage = param.baggage;
        this.initialRoute = param.initialRoute;
        this.toReturnRoute = param.toReturnRoute;
        this.returnRoute = param.returnRoute;
        this.fromReturnRoute = param.fromReturnRoute;
    }
}

export class BaggageRecommendReturnResult {
    recommendations: BaggageRecommendReturn[];
    companyProfiles: CompanyProfile[];

    constructor(param: ExcludeMethods<BaggageRecommendReturnResult>) {
        this.recommendations = param.recommendations;
        this.companyProfiles = param.companyProfiles;
    }
}
